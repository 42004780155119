import React from "react"
// import Link from 'gatsby-link'
import Layout from '../components/layout-default'

const contact = () => {
    const name = "Contact us";
    return (
        <Layout name={name}>

        </Layout>
        
    )

}

export default contact